import FastImage from "../../../globals/elements/fastimg";
import SectionHome1Plans from "../../sections/home/home1/section-home1-plans";
import SectionHome1Team from "../../sections/home/home1/section-home1-team";
import SectionHome2Video from "../../sections/home/home2/section-home2-video";
import SectionHome3WhatWeDo from "../../sections/home/home3/section-home3-what-we-do";
import SectionHome3WhyChooseUs from "../../sections/home/home3/section-home3-why-choose-us";
import { publicUrlFor } from "../../../globals/constants";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { pagesFetch } from "../../../store/pagesSlice";
import { servicesFetch } from "../../../store/servicesSlice";
import { useNavigate, useParams } from "react-router-dom";
import { aboutFetch } from "../../../store/aboutSlice";
import { t } from "i18next";
import SectionBanner from "../../sections/common/banner/banner";
import Loader from "../Loader";
import SwiperWrapper from "../SwiperWrapper";
import { Autoplay, FreeMode } from "swiper/modules";
import { Pagination } from "react-bootstrap";
import { SwiperSlide } from "swiper/react";

function AboutUsPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lang } = useParams();
  const locale = lang;
  const about = useSelector((state) => state.about);
  const { homeData, pages } = useSelector((state) => state);

  const { isLoading, loaded, value, error } = about;
  const servicesData = useSelector((state) => state.services);
  console.log({ servicesData });
  // const servicesValue = servicesData.value;
  const { services } = servicesData;
  const servicesLoaded = servicesData.loaded;

  const { servicesSection } = servicesData;

  useEffect(() => {
    // dispatch(pagesFetch({ locale }));
    dispatch(aboutFetch({ locale }));
    dispatch(servicesFetch({ locale }));
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  if (error) {
    return <h1>Fetching data error!</h1>;
  }

  const currentpath = {
    crumb: t("about"),
    title: t("about"),
  };

  return (
    <>
      <SectionBanner data={currentpath} />

      {/* Our Mission */}
      {loaded && <SectionHome3WhatWeDo about={value} />}

      {/* Why Choose us */}
      {/* <SectionHome3WhyChooseUs /> */}

      {/* Our Services */}
      <div className="section-full bg-change-section overlay-wraper p-t80 p-b50">
        <div className="overlay-main bg-secondry opacity-09" />
        <div className="container">
          <div className="section-head text-center text-white">
            {servicesLoaded && (
              <h2 data-title={"Services"}>{servicesSection.title} </h2>
            )}
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          <div className="section-content">
            <div className="row d-flex justify-content-center">
              {servicesLoaded &&
                services.map((item) => {
                  return (
                    <div
                      onClick={() =>
                        navigate(`/${lang}/service/detail/${item.id}`)
                      }
                      key={item.id}
                      className="col-lg-4 col-md-6 m-b30"
                    >
                      <div className="mt-icon-box-wraper center bgcall-block overflow-hidden bdr-1 bdr-solid bdr-primary hover-shadow">
                        <FastImage
                          src={item.image}
                          proImage={item.image}
                          alt={item.title}
                          style={{ height: 200 }}
                        />
                        <div className="icon-content  p-a30">
                          <h4 className="mt-tilte  m-b15 site-text-primary">
                            {item.title}
                          </h4>
                          <div
                            className="text-white"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* Our Team */}
      {/* <SectionHome1Team /> */}

      {/*Video Section */}
      {/* <SectionHome2Video /> */}

      {/* OUR PLANS  */}
      {/* <SectionHome1Plans /> */}
    </>
  );
}
export default AboutUsPage;
