import React from "react";
import SwiperWrapper from "../../../components/SwiperWrapper";
import { SwiperSlide } from "swiper/react";
import styles from "./Slider.module.css";
import FastImage from "../../../../globals/elements/fastimg";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { NavLink, useParams } from "react-router-dom";
import { t } from "i18next";

const HomeSlider = ({ slides }) => {
	const {lang} = useParams();

  const displaySliders = () => {
    return slides?.map((slide) => {
      return (
        <SwiperSlide key={slide.id} className={styles.swiperSlide}>
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              <FastImage
                proImage={slide.image}
                alt={slide.title}
                className={styles.image}
              />
            </div>
            <div className={`${styles.content} d-flex align-items-center justify-content-center flex-column gap-3`}>
              <h2 className={styles.title}>{slide.title}</h2>
              <div className={styles.subtitle}>{slide.subtitle}</div>
              <NavLink to={`/${lang}/services`} className="site-button">
                {t("read_more")}
              </NavLink>{" "}
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <SwiperWrapper
      slidesPerView={1}
      loop
      autoplay={true}
      pagination={{
        clickable: true,
      }}
			navigation
      modules={[Navigation, Autoplay]}
      className={styles.swiper}
    >
      {displaySliders()}
    </SwiperWrapper>
  );
};

export default HomeSlider;
