import FastImage from "../../../../globals/elements/fastimg";
import { publicUrlFor } from "../../../../globals/constants";

function SectionHome1Testimonials({ testimonials, testimonialSection }) {
  
  const displayAll = () => {
    return testimonials?.map((item) => {
      return (
        <div key={item.id} className="item">
          <div className="testimonial-2  clearfix bg-white">
            <div className="testimonial-detail">
              <div className="testimonial-pic shadow">
                <FastImage
                  src={item.image}
                  proImage={item.image}
                  width={100}
                  height={100}
                  alt=""
                />
              </div>
            </div>
            <div className="testimonial-text">
              <strong className="testimonial-name">{item.title}</strong>
              <span className="testimonial-position">{item.subtitle}</span>
              <span className="fa fa-quote-left" />
              <div dangerouslySetInnerHTML={{ __html: item.description }} />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div
      className="section-full p-t80 p-b80 bg-center bg-full-height bg-no-repeat bg-gray"
      style={{
        backgroundImage: `url(${publicUrlFor("images/background/bg-map.png")})`,
      }}
    >
      <div className="container">
        {/* TITLE START */}
        <div className="section-head text-center">
          <h2 data-title="Testimonials">{testimonialSection.title}</h2>
          <h5 data-title="Testimonials">{testimonialSection.subtitle}</h5>
          <div className="mt-separator-outer">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END */}
        <div className="section-content">
          <div className="owl-carousel home-carousel-1">{displayAll()}</div>
        </div>
      </div>
    </div>
  );
}

export default SectionHome1Testimonials;
