import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: [],
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
    setProducts: (state, action) => {
      console.log({action})
        state.value = [...action.payload];
    }
  },
})

// Action creators are generated for each case reducer function
export const { setProducts } = productsSlice.actions
const productsReducer = productsSlice.reducer
export default productsReducer;