import { useDispatch, useSelector } from "react-redux";
import FastImage from "../../../globals/elements/fastimg";
import { t } from "i18next";
import SectionBanner from "../../sections/common/banner/banner";
import {
  addQty,
  decreaseQty,
  removeItem,
  saveCart,
  setTotalPriceCart,
} from "../../../store/cartSlice";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import OrderSection from "../order";

function ShopCartPage() {
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const { lang } = useParams();

  
  const handleRemoveItem = (item) => {
    dispatch(removeItem(item.id));
  };

  const addQtyItem = (item) => {
    dispatch(addQty(item.id));
  };

  const decreaseAmount = (item) => {
    dispatch(decreaseQty(item.id));
  };

  const displayCart = () => {
    return cart.items?.map((item) => {
      return (
        <tr>
          <td data-title="Code">
            <FastImage proImage={item.image} alt="" />
          </td>
          <td data-title="Company">{item.title}</td>
          <td data-title="Price" className="numeric">
            SAR{item.price}
          </td>
          <td data-title="Quantity" className="numeric">
            <div
              style={{
                fontSize: 22,
              }}
              className="d-flex align-items-center justify-content-center gap-3"
            >
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => addQtyItem(item)}
              >
                +
              </span>
              <span>{item.qty}</span>
              <span
                style={{
                  cursor: "pointer",
                }}
                onClick={() => decreaseAmount(item)}
              >
                -
              </span>
            </div>
          </td>
          <td data-title="Open" className="numeric">
            <div
              style={{
                color: "var(--color-main)",
                cursor: "pointer",
              }}
              onClick={() => handleRemoveItem(item)}
            >
              <i className="fa fa-times" />
            </div>
          </td>
        </tr>
      );
    });
  };

  const currentpath = {
    crumb: t("cart"),
    title: t("cart"),
  };

  const dir = lang === "en" ? "ltr" : "rtl";

  return (
    <>
      <SectionBanner data={currentpath} />

      {/* Best Services */}
      <div dir={dir} className="section-full  p-t80 p-b50">
        <div className="container">
          <div className="section-content m-b30">
            <div className="section-head text-center">
              <h2 style={{ color: "var(--color-main)" }} data-title={"Cart"}>
                {t("cart")}
              </h2>
              <div className="mt-separator-outer">
                <div className="mt-separator site-bg-primary" />
              </div>
            </div>
            <div id="no-more-tables">
              <table className="table-bordered  table-condensed cf mt-responsive-table shopping-table">
                <thead className="cf bg-gray">
                  <tr>
                    <th>{t("image")}</th>
                    <th>{t("name")}</th>
                    <th className="numeric">{t("price")}</th>
                    <th className="numeric">{t("quantity")}</th>
                    <th className="numeric">{t("remove")}</th>
                  </tr>
                </thead>
                <tbody>{displayCart()}</tbody>
              </table>
              <div className="cart-buttons ">
                <div className="d-flex align-items-center gap-3">
                  <Link
                    to={`/${lang}/shop/products`}
                    className="m-b15 site-button text-uppercase"
                  >
                    {t("continue_shopping")}
                  </Link>
                </div>
              </div>
            </div>
            {/* order form start */}
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6">
                <h5>{t('order_now')}</h5>
                <OrderSection cart={cart} />
              </div>
              <div className="col-sm-12 col-md-4 col-lg-4">
                <table className="table-bordered  table-condensed cf mt-responsive-table max-w300 ">
                  <thead className="cf text-center bg-gray">
                    <tr>
                      <th>{t("total")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td data-title={t("total")}>
                        <b> ${cart.totalPrice}</b>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* order form end */}
          </div>
        </div>
      </div>
    </>
  );
}
export default ShopCartPage;
