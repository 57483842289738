import axios from "axios";

export const url = "https://yousab-tech.com/elmandra-alarabia/public/api";
export let clientUrl;

if(process.env.NODE_ENV === 'development') {
    clientUrl = 'http://localhost:5173'
} else {
    clientUrl = "https://elmandra.yousab-tech.com";
}

export const api = axios.create({
    baseURL: url
})