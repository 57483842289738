import { NavLink, useParams } from "react-router-dom";
import { publicUrlFor, route } from "../../../../globals/constants";
import { useSelector } from "react-redux";
import { t } from "i18next";

function SectionBanner(props) {
  const { data } = props;
  const settings = useSelector((state) => state.settings.value);
  const { lang } = useParams();
  const dir = lang === "en" ? "ltr" : "rtl";
  return (
    <>
      <div
        className="mt-bnr-inr overlay-wraper"
        style={{
          backgroundImage: `url(${settings.breadcrumb})`,
        }}
        dir={dir}
      >
        <div
          style={{
            opacity: 0.5,
          }}
          className="overlay-main bg-black opacity-07"
        />
        <div className="container">
          <div
            className="mt-bnr-inr-entry"
            style={{
              textAlign: "start",
            }}
          >
            <h1
              style={{
                textShadow: "1px 1px 1px #000",
              }}
              className="text-white"
            >
              {data.title}
            </h1>
            {/* Breadcrumb */}
            <ul
              style={{
                textShadow: "1px 1px 1px #000",
              }}
              className="mt-breadcrumb d-flex align-items-center justify-content-start gap-2"
            >
              <li>
                <NavLink to={route.home.HOME1}>{t("home")}</NavLink>
              </li>
              <span style={{ color: "#fff" }}>{"/"}</span>
              <li>{data.crumb}</li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default SectionBanner;
