import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getService } from "../../api/servicesApi";

export const serviceFetch = createAsyncThunk("service/fetch", getService);

const initialState = {
  value: null,
  isLoading: false,
  loaded: false,
  error: null,
};

export const serviceSlice = createSlice({
  name: "service",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(serviceFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(serviceFetch.fulfilled, (state, action) => {
			console.log({action})
      state.isLoading = false;
      state.loaded = true;
      state.value = action.payload.data.service;
    });
    builder.addCase(serviceFetch.rejected, (state, action) => {
      state.loaded = false;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = serviceSlice.actions;
const singleServiceReducer = serviceSlice.reducer;
export default singleServiceReducer;
