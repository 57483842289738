import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { searchProducts } from "../../../api/productsApi";
import { getCategories } from "../../../api/categoriesApi";
import { Checkbox, FormControlLabel, Slider } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { t } from "i18next";

const FilterProducts = ({filterProducts, values, setValues, prices, setPrices}) => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const locale = lang;
  const dir = lang === "en" ? "ltr" : "rtl";
  const configurations = ["4*2", "6*2", "8*4", "6*4", "8*2"];
  const [categories, setCategories] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
 
  const {
    title,
    mileage,
    category_id,
    suspensionType,
    kind,
    gearBox,
    configuration,
    minPrice,
    maxPrice,
  } = values;

  const handleReload = () => {
    navigate(0);
  };

  useEffect(() => {
    getCategories({ locale }).then((res) => {
      setCategories(res.data.categories);
    });
  }, []);

  const suspensionTypes = [
    t("suspension_type"),
    t("air_suspension"),
    t("full_air_suspension"),
  ];
  const kinds = [
    t("tractor_unit"),
    t("semi_trailer"),
    t("trailer"),
    t("tripper"),
  ];

  const handleChange = (e) => {
    if (e.target.name === "title") {
      setValues({ ...values, title: e.target.value });
    }
    if (e.target.name === "mileage") {
      setValues({ ...values, mileage: e.target.value });
    }
    if (e.target.name === "category_id") {
      const categoryId = parseInt(e.target.value);
      let newArr;
      if (category_id.includes(categoryId)) {
        newArr = category_id.filter((item) => item !== categoryId);
      } else {
        newArr = [...category_id, categoryId];
      }

      setValues({ ...values, category_id: newArr });
    }
    if (e.target.name === "suspensionType") {
      const categoryId = e.target.value;
      let newArr;
      if (suspensionType.includes(categoryId)) {
        newArr = suspensionType.filter((item) => item !== categoryId);
      } else {
        newArr = [...suspensionType, categoryId];
      }

      setValues({ ...values, suspensionType: newArr });
    }
    if (e.target.name === "kind") {
      const categoryId = e.target.value;
      let newArr;
      if (kind.includes(categoryId)) {
        newArr = kind.filter((item) => item !== categoryId);
      } else {
        newArr = [...kind, categoryId];
      }

      setValues({ ...values, kind: newArr });
    }
    if (e.target.name === "gearBox") {
      const categoryId = e.target.value;
      let newArr;
      if (gearBox.includes(categoryId)) {
        newArr = gearBox.filter((item) => item !== categoryId);
      } else {
        newArr = [...gearBox, categoryId];
      }

      setValues({ ...values, gearBox: newArr });
    }
    if (e.target.name === "configuration") {
      const categoryId = e.target.value;
      let newArr;
      if (configuration.includes(categoryId)) {
        newArr = configuration.filter((item) => item !== categoryId);
      } else {
        newArr = [...configuration, categoryId];
      }

      setValues({ ...values, configuration: newArr });
    }
  };

  const handlePricesChange = (event, newValue) => {
    setPrices(newValue);
    setValues({...values, minPrice: newValue[0],maxPrice: newValue[1]})
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    filterProducts(values);
  };

  return (
    <Form
      className="d-flex flex-column align-items-start justify-content-center pb-5 mb-4"
      onSubmit={handleSubmit}
      dir={dir}
    >
      <Form.Label>{t("search")}</Form.Label>
      <Form.Control
        type="text"
        id="title"
        aria-describedby="title"
        name="title"
        value={title}
        placeholder={t("product_title")}
        onChange={handleChange}
      />
      <div
        style={{
          // height: 300,
          // overflowY: "scroll",
          marginTop: 30,
        }}
      >
        <Form.Label>{t("select_category")}</Form.Label>
        {categories?.map((category) => {
          return (
            <div>
              <FormControlLabel
                key={category.id}
                id={`default-${category.id}`}
                control={<Checkbox />}
                label={category.title}
                name="category_id"
                checked={category_id.indexOf(category.id) !== -1}
                value={category.id}
                onChange={handleChange}
              />
            </div>
          );
        })}
      </div>
      {/* Suspension type */}
      <Form.Label>{t("suspension_type")}</Form.Label>
      {suspensionTypes?.map((sus, index) => {
        return (
          <div>
            <FormControlLabel
              key={index}
              id={`default-${sus}`}
              control={<Checkbox />}
              label={sus}
              name="suspensionType"
              checked={suspensionType.indexOf(sus) !== -1}
              value={sus}
              onChange={handleChange}
            />
          </div>
        );
      })}
      {/* kind section */}
      <Form.Label>{t("kind")}</Form.Label>
      {kinds?.map((item, index) => {
        return (
          <div>
            <FormControlLabel
              key={index}
              id={`default-${item}`}
              control={<Checkbox />}
              label={item}
              name="kind"
              checked={kind.indexOf(item) !== -1}
              value={item}
              onChange={handleChange}
            />
          </div>
        );
      })}
      {/* gear type section */}
      <Form.Label>{t("gear_box")}</Form.Label>
      {gearBox?.map((item, index) => {
        return (
          <div>
            <FormControlLabel
              key={index}
              id={`default-${item}`}
              control={<Checkbox />}
              label={item}
              name="gearBox"
              checked={gearBox.indexOf(item) !== -1}
              value={item}
              onChange={handleChange}
            />
          </div>
        );
      })}
      {/* configuration section */}
      <Form.Label>{t("configuration")}</Form.Label>
      {configurations?.map((config, index) => {
        return (
          <div>
            <FormControlLabel
              key={index}
              id={`default-${config}`}
              control={<Checkbox />}
              label={config}
              name="configuration"
              checked={configuration.indexOf(config) !== -1}
              value={config}
              onChange={handleChange}
            />
          </div>
        );
      })}

      {/* prices section */}
      <Form.Label>{t("prices")}</Form.Label>
      <Slider
        value={prices}
        onChange={handlePricesChange}
        valueLabelDisplay="auto"
        min={1000}
        max={120000}
      />

      {/* mileage section */}
      <Form.Label>{t("mileage")}</Form.Label>
      <Form.Control
        type="number"
        id="mileage"
        aria-describedby="mileage"
        name="mileage"
        value={mileage}
        placeholder={t("mileage")}
        onChange={handleChange}
      />
      {/* mileage section */}
      <Form.Label className="mt-3">{t("manyear")}</Form.Label>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker onChange={handleDateChange} />
      </LocalizationProvider>
      <div className="d-flex align-items-center jutify-content-center gap-3">
        {/* <button className="mt-3 site-button" type="submit">
          {t("search")}
        </button> */}
        <button
          onClick={handleReload}
          className="mt-3 site-button"
          type="button"
        >
          {t("reset")}
        </button>
      </div>
    </Form>
  );
};

export default FilterProducts;
