import { api } from "./base";

export const getServices = async ({ locale }) => {
  api.defaults.headers = { locale };
  const res = await api.get('/services');
  return res.data;
};

export const getService = async ({ locale, serviceId }) => {
  api.defaults.headers = { locale };
  const res = await api.get(`/service/${serviceId}`);
  return res.data;
};
