import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: {},
  loaded: false,
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.value = action.payload;
      state.loaded = true
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSettings } = settingsSlice.actions;

export default settingsSlice.reducer;
