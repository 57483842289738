import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getHomeData } from "../../api/homeApi";

export const homeFetch = createAsyncThunk("home/fetch", getHomeData);

const initialState = {
  isLoading: false,
  error: null,
  loaded: false,
  sliders: null,
  counters: null,
  faqs: null,
  features: null,
  partners: null,
  services: null,
  teams: null,
  testimonials: null,
  faqSection: null,
  aboutSection: null,
  serviceSection: null,
  teamSection: null,
  chooseusSection: null,
  chooseus: null,
  testimonialSection: null,
};

export const homeSlice = createSlice({
  name: "home",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(homeFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(homeFetch.fulfilled, (state, action) => {
      // console.log({ action: action.payload });
      state.isLoading = false;
			state.aboutSection = action.payload.data['about-section'];
			state.faqSection = action.payload.data['faq-section'];
			state.serviceSection = action.payload.data['service-section'];
			state.teamSection = action.payload.data['team-section'];
			state.testimonialSection = action.payload.data['testimonial-section'];
			state.chooseusSection = action.payload.data['chooseus-section'];
			state.counters = action.payload.data.counters;
			state.faqs = action.payload.data.faqs;
			state.features = action.payload.data.features;
			state.partners = action.payload.data.partners;
			state.services = action.payload.data.services;
			state.sliders = action.payload.data.sliders;
			state.teams = action.payload.data.teams;
			state.chooseus = action.payload.data.chooseus;
			state.testimonials = action.payload.data.testimonials;
      state.loaded = true;
    });
    builder.addCase(homeFetch.rejected, (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = homeSlice.actions;
const homeReducer = homeSlice.reducer;;
export default homeReducer;
