import { api } from "./base";

export const getProducts = async ({ locale, values }) => {
  api.defaults.headers = { locale };
  const res = await api.post('/products', values);
  return res.data;
};

export const getProduct = async ({ locale, productId }) => {
  api.defaults.headers = { locale };
  const res = await api.get(`/product/${productId}`);
  return res.data;
};
export const searchProducts = async ({ locale, values }) => {
  console.log({values})
  api.defaults.headers = { locale };
  const res = await api.post(`/products`, values);
  return res.data;
};
