import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAbout } from '../../api/aboutApi';

export const aboutFetch = createAsyncThunk('about/fetch', getAbout);

const initialState = {
  value: null,
	isLoading: false,
	loaded: false,
	error: null
}

export const aboutSlice = createSlice({
  name: 'about',
  initialState,
  extraReducers: builder => {
		builder.addCase(aboutFetch.pending, (state, action) => {
			state.isLoading = true;
		});
		builder.addCase(aboutFetch.fulfilled, (state, action) => {
			state.isLoading = false;
			state.loaded = true;
			state.value = action.payload.data['about-section'];
		});
		builder.addCase(aboutFetch.rejected, (state, action) => {
			state.loaded = false;
			state.error = action.payload
		});
	}
})

// Action creators are generated for each case reducer function
export const {  } = aboutSlice.actions
const aboutReducer = aboutSlice.reducer
export default aboutReducer;