import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink, useParams } from "react-router-dom";

function SectionHome3WhatWeDo({ about }) {
  console.log({ about });
	const {lang} = useParams();
	const dir = lang === 'en' ? 'ltr' : 'rtl';
  return (
    <div dir={dir} className="section-full  p-t80 p-b50">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="mt-box m-b30">
              <div className="mt-thum-bx mt-img-effect">
                <FastImage
                  src={about.image[0].url}
                  proImage={about.image[0].url}
                  alt={about.title}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="mt-box sx-what-wedo">
              <span className="site-text-primary display-block title-second text-uppercase font-20 font-weight-600 display-block m-b15">
                {about.subtitle}
              </span>
              <h2 className="m-t0">{about.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: about.description }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHome3WhatWeDo;
