import { useEffect, useState } from "react";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink, useLocation, useParams } from "react-router-dom";
import { getSettings } from "../../../../api/settingsApi";
import { useDispatch, useSelector } from "react-redux";
import { setSettings } from "../../../../store/settingsSlice";
import { useTranslation } from "react-i18next";
import { clientUrl } from "../../../../api/base";

function SectionHeader1() {
  const [isActive, setIsActive] = useState(false);

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const location = useLocation();
  const dir = lang === "en" ? "ltr" : "rtl";
  const settings = useSelector((state) => state.settings.value);

  useEffect(() => {
    loadScript("js/sticky-header.js");
  });

  const handleToggleMenu = () => {
    setIsActive(!isActive);
  };

  const handleLang = (locale) => {
    const oldLang = location.pathname.slice(0, 3);
    const newPath = location.pathname.split(oldLang)[1];
    i18n.changeLanguage(locale);
    const replaced = `${locale}${newPath}`;
    window.location.replace(clientUrl + "/" + replaced);
  };

  const displaySocials = () => {
    return settings?.socials?.map((social) => {
      return (
        <li key={social.id}>
          <a href={social.contact} className={social.icon} />
        </li>
      );
    });
  };

  return (
    <header
      className={
        "site-header header-style-1 mobile-sider-drawer-menu " +
        (isActive ? "active" : "")
      }
      dir={dir}
    >
      <div className="top-bar top-bar-liner bg-secondry">
        <div className="container">
          <div className="row">
            <div className="mt-topbar-right">
              <ul className="list-unstyled  tb-info-liner">
                <li>
                  <i className="site-text-primary" />
                  Your Trusted 24 Hours Service Provider!
                </li>
              </ul>
              <ul className="list-inline tb-social-liner">
                {displaySocials()}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Search Link */}
      <div className="header-middle bg-white">
        <div className="container">
          <div className="logo-header">
            <NavLink to={route.home.HOME1}>
              <FastImage
                proImage={settings?.logo}
                // src="images/logo-1.png"
                width={216}
                height={37}
                alt=""
              />
            </NavLink>
          </div>
          <div className="header-info">
            <ul>
              <li>
                <div>
                  <div className="icon-sm">
                    <span className="icon-cell  site-text-primary">
                      <i className="iconmoon-smartphone" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <strong>Phone Number</strong>
                    <span>
                      {settings?.phones?.length
                        ? settings?.phones[0]?.contact
                        : null}
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div>
                  <div className="icon-sm">
                    <span className="icon-cell  site-text-primary">
                      <i className="iconmoon-envelope" />
                    </span>
                  </div>
                  <div className="icon-content">
                    <strong>Email Address</strong>
                    <span>
                      {settings?.phones?.length
                        ? settings?.emails[0]?.contact
                        : null}
                    </span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="sticky-header main-bar-wraper">
        <div className="main-bar header-botton nav-bg-primary">
          <div className="container">
            {/* NAV Toggle Button */}
            <button
              id="mobile-side-drawer"
              data-target=".header-nav"
              data-toggle="collapse"
              type="button"
              className="navbar-toggler collapsed"
              onClick={handleToggleMenu}
            >
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar icon-bar-first" />
              <span className="icon-bar icon-bar-two" />
              <span className="icon-bar icon-bar-three" />
            </button>
            {/* ETRA Nav */}

            <div className="logo-show-in-mobile">
              <NavLink to={`/${lang}/index`}>
                <FastImage
                  proImage={settings?.logo}
                  width={216}
                  height={37}
                  alt=""
                />
              </NavLink>
            </div>
            {/* MAIN Vav */}
            <div className="header-nav navbar-collapse collapse d-flex justify-content-start">
              <ul className=" nav navbar-nav">
                <li className="active">
                  <NavLink to={`/${lang}/index`}>{t("home")}</NavLink>
                </li>
                <li>
                  <NavLink to={route.shop.PRODUCTS}>Products</NavLink>
                </li>
                <li>
                  <NavLink to={route.services.SERVICES}>Services</NavLink>
                </li>
                <li>
                  <NavLink to={route.pages.ABOUT}>About us</NavLink>
                </li>
                <li>
                  <NavLink to={route.pages.contact.CONTACT1}>
                    Contact us
                  </NavLink>
                </li>
                <li>
                  <button
                    onClick={(e) => handleLang(lang === "en" ? "ar" : "en")}
                  >
                    {lang === "en" ? "عربي" : "En"}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}
export default SectionHeader1;
