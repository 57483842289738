import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { postContactUs } from "../../../../api/contactusApi";
import SectionBanner from "../../../sections/common/banner/banner";
import Loader from "../../Loader";
import AlertToast from "../../Alert";
import Error from "../../Error";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function ContactUs1Page() {
  const settings = useSelector((state) => state.settings);
  const { loaded, value } = settings;
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [phone, setPhone] = useState("");


  const [text, setText] = useState("");
  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const { name, email, message } = values;
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const locale = lang;
  const dir = lang === "en" ? "ltr" : "rtl";

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (isLoading) {
        setIsLoading(false);
      }
    }, 2000);
    return () => clearTimeout(timeout);
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisabled(true);
    values["phone"] = phone;
    postContactUs({ locale, values })
      .then((res) => {
        setText(lang === "en" ? "Order is successful!" : "تم الطلب بنجاح!");
        setOpen(true);
        setDisabled(false);
        setError(false);
        setErrors({});
      })
      .catch((err) => {
        setError(true);
        setErrors(err.response.data.data);
        setDisabled(false);
      });
  };

  const currentpath = {
    crumb: t("contact_us"),
    title: t("contact_us"),
  };


  return (
    <Fragment>
      <AlertToast open={open} message={text} handleClose={handleClose} />

      <SectionBanner data={currentpath} />
      {/* CONTACT DETAIL BLOCK */}

      <div dir={dir} className="section-full p-t80">
        <div className="container">
          <div className="section-head">
            <h2>{t("contact")} </h2>
            <div className="mt-separator-outer m-b30">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          <div className="section-content m-b30">
            <div className="row">
              <div className="col-md-3 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                    <i className="iconmoon-smartphone-1" />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">{t("phone")}</h4>
                    <p dir={"ltr"}>{loaded && value.phones[0].contact}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                  <WhatsAppIcon />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">{t("whatsapp")}</h4>
                    <p dir={"ltr"}>{loaded && value.whatsapp[0].contact}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                    <i className="iconmoon-email" />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">{t("email")}</h4>
                    <p>{loaded && value.emails[0].contact}</p>
                  </div>
                </div>
              </div>
              <div className="col-md-3 col-sm-12 m-b30">
                <div className="mt-icon-box-wraper center p-a30 bg-gray">
                  <div className="icon-sm m-b10">
                    <i className="iconmoon-travel" />
                  </div>
                  <div className="icon-content">
                    <h4 className="site-text-primary">{t("location")}</h4>
                    <p>{loaded && value.address1}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6">
          <div className="section-full p-t80">
            <div className="container">
              {/* Google Map BLock */}
              <div className="section-content">
                {/* Location BLock */}
                <div className="mt-box">
                  <div className="gmap-outline">
                    <div className="google-map-2" style={{ width: "100%" }}>
                      <div dangerouslySetInnerHTML={{ __html: value.map }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div dir={dir} className="section-full">
            <div className="container">
              <div className="section-head">
                <h2>{t("contact_us")} </h2>
                <div className="mt-separator-outer m-b30">
                  <div className="mt-separator site-bg-primary" />
                </div>
              </div>
              {/* GOOGLE MAP & CONTACT FORM */}
              <div className="section-content m-b50">
                {/* CONTACT FORM*/}
                <div className="mt-box">
                  {success && (
                    <div class="alert alert-success" role="alert">
                      {alert}
                    </div>
                  )}
                  {error && (
                    <Error
                      error={errors}
                      errorsShow={error}
                      handleClose={handleClose}
                    />
                  )}

                  <div className="p-a30 bg-gray radius-10 cons-contact-form-wrap">
                    <form className=" contact-style-1" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              name="name"
                              type="text"
                              onChange={handleChange}
                              value={name}
                              className="form-control"
                              placeholder={t("name")}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input
                              name="email"
                              type="text"
                              value={email}
                              className="form-control"
                              onChange={handleChange}
                              placeholder={t("email")}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group" dir="ltr">
                            <PhoneInput
                              name="phone" 
                              country={lang === "ar" ? "sa" : "sa"}
                              value={phone}
                              onChange={(phone) => setPhone(phone)}
                              dir="ltr"
                              inputClass="form-control"
                              inputStyle={{
                                borderTop: 'none',
                                background: 'transparent',
                                borderColor: "#e1e6eb",
                                boxShadow: "none",
                                width: "100%",
                                height: 40,
                                fontSize: 13,
                                lineHeight: 20,
                                borderRadius: "6px !important",
                                direction: lang === "en" ? "ltr" : "ltr",
                                paddingInlineStart: lang === "ar" && 50,
                              }}
                            />
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea
                              name="message"
                              rows={5}
                              className="form-control "
                              placeholder={t("message")}
                              onChange={handleChange}
                              value={message}
                            >
                              {message}
                            </textarea>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button
                            name="submit"
                            type="submit"
                            className="site-button"
                          >
                            {t("submit")}
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default ContactUs1Page;
