import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  values: {
    title: "",
    mileage: "",
    category_id: [],
    suspensionType: [],
    kind: [],
    gearBox: [],
    configuration: [],
    minPrice: 1000,
    maxPrice: 120000,
  },
};

export const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setValues: (state, action) => {
      console.log({ action });
      state.values = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setValues } = filterSlice.actions;
const filterReducer = filterSlice.reducer;
export default filterReducer;
