import { Fragment, useEffect } from "react";
import FastImage from "../../../globals/elements/fastimg";
import { loadScript, route } from "../../../globals/constants";
import { Link, NavLink, useParams } from "react-router-dom";
import { t } from "i18next";
import SectionBanner from "../../sections/common/banner/banner";
import { useDispatch, useSelector } from "react-redux";
import { serviceFetch } from "../../../store/singleServiceSlice";
import Loader from "../Loader";
import { servicesFetch } from "../../../store/servicesSlice";
import HelmetComponent from "../HelmetComponent";

function ServiceDetailPage() {
  const dispatch = useDispatch();
  const serviceData = useSelector((state) => state.service);
  const { loaded, isLoading, value, error } = serviceData;

  const servicesData = useSelector((state) => state.services);

  // const { isLoading, loaded, error, processSection, services, processes } =
  //   data;
  const servicesLoading = servicesData.isLoading;
  const servicesLoaded = servicesData.loaded;
  const servicesError = servicesData.error;
  const services = servicesData.services;

  const service = value;

  const { lang, serviceId } = useParams();
  const locale = lang;
  useEffect(() => {
    loadScript("js/custom.js");
  });

  useEffect(() => {
    dispatch(serviceFetch({ locale, serviceId }));
    dispatch(servicesFetch({ locale }));
  }, [dispatch, serviceId]);

  if (isLoading && servicesLoading) {
    return <Loader />;
  }

  if (error && servicesError) {
    return <h1>Fetching data error!</h1>;
  }

  const displayServices = () => {
    return (
      servicesLoaded &&
      services?.map((item, index) => {
        if (index > 2) {
          return (
            <div key={index} dir={dir} className="col-lg-4 col-md-6 m-b30">
              <Link to={`/${lang}/service/detail/${item.id}`}>
                <div className="mt-box bg-white bdr-1 bdr-solid bdr-gray-light mt-service-1">
                  <div className="mt-thum-bx mt-img-overlay3 mt-img-effect">
                    <FastImage
                      src={item.image}
                      proImage={item.image}
                      style={{ height: 200, objectFit: "cover" }}
                      alt={item.title}
                    />
                    <div className="overlay-bx">
                      <div className="overlay-icon">
                        {/* <NavLink to={route.services.DETAIL}>
                                  <i className="fa fa-external-link-square mt-icon-box-xs" />
                                </NavLink> */}
                        <a className="mfp-link">
                          <i className="fa fa-search-plus mt-icon-box-xs" />
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mt-info p-a20">
                    <h4 className="m-t0">{item.title}</h4>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: item.description.substring(0, 80) + "...",
                      }}
                      style={{ color: "#1a1a1a" }}
                    />
                  </div>
                </div>
              </Link>
            </div>
          );
        }
      })
    );
  };

  const currentpath = {
    crumb: t("our_services"),
    title: t("our_services"),
  };

  const dir = lang === "en" ? "ltr" : "rtl";

  return (
    <Fragment>
      <HelmetComponent
        title={service?.title}
        description={service?.description}
        image={service?.image}
      />
      <SectionBanner data={currentpath} />

      {/* Service Detail */}
      <div dir={dir} className="section-full  p-t80 p-b50  ">
        <div className="container  bg-white ">
          <div className="section-content ">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="mt-box">
                  <h2 className="m-t0">{loaded && service.title}</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: loaded && service.description,
                    }}
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                {loaded && (
                  <div
                    style={{
                      height: 300,
                    }}
                  >
                    <img
                      src={service.image}
                      alt={service.title}
                      style={{
                        width: "100%",
                        height: "100% !important",
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-full p-t80 p-b50 bg-gray">
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center">
            <h2 data-title="Services">{t("other_services")}</h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="row d-flex justify-content-center">
            {servicesLoaded && displayServices()}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
export default ServiceDetailPage;
