import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getServices } from "../../api/servicesApi";

export const servicesFetch = createAsyncThunk("services/fetch", getServices);

const initialState = {
  isLoading: false,
  loaded: false,
  error: null,
  services: null,
  processes: null,
  processSection: null,
  servicesSection: null,
};

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(servicesFetch.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(servicesFetch.fulfilled, (state, action) => {
      console.log({ action });
      state.isLoading = false;
      state.loaded = true;
      state.services = action.payload.data.services;
      state.processes = action.payload.data.processes;
      state.processSection = action.payload.data['process-section'];
      state.servicesSection = action.payload.data['service-section'];
    });
    builder.addCase(servicesFetch.rejected, (state, action) => {
      state.isLoading = true;
      state.error = action.payload;
    });
  },
});

// Action creators are generated for each case reducer function
export const {} = servicesSlice.actions;
const servicesReducer = servicesSlice.reducer;
export default servicesReducer;
