import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";
import _data from "../../../../globals/data/data.json";

function SectionHome1Team({ team, teamSection }) {
  return (
    <div className="section-full text-center mt-our-team bg-gray p-t80 p-b50">
      <div className="container">
        {/* TITLE START*/}
        <div className="section-head text-center">
          <h2 data-title="Team">{teamSection.title}</h2>
          <h5 data-title="Team">{teamSection.subtitle}</h5>
          <div className="mt-separator-outer">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END*/}
        <div className="section-content">
          <div className="row">
            {team.map((item, index) => {
              return (
                <div
                  key={index}
                  className="col-md-4 col-sm-6 col-xs-6 col-xs-100pc  m-b30"
                >
                  <div className="mt-team-seven ">
                    <div className="mt-team-media mt-img-effect off-color bg-white">
                      <NavLink to={route.pages.team.DETAIL}>
                        <FastImage
                          src={item.image}
                          proImage={item.image}
                          alt=""
                        />
                      </NavLink>
                    </div>
                    <div className="mt-team-info text-center p-a30 bg-white">
                      <h4 className="mt-team-title m-t0 m-b15">
                        <NavLink
                          to={route.pages.team.DETAIL}
                          className=" site-text-primary"
                        >
                          {item.title}
                        </NavLink>
                      </h4>
                      <p>{item.subtitle}</p>
                      {/* <div className="social-share-btn-content team-one-show-hide">
                        <ul
                          style={{
                            background: "#000",
                          }}
                          className="social-icons social-square"
                        >
                          <li>
                            <a href={item.facebook}>
                              <i className="fa fa-facebook"></i>
                            </a>
                          </li>
                          <li>
                            <a href={item.twitter}>
                              <i className="fa fa-twitter"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href={item.linkedin}
                            >
                              <i className="fa fa-linkedin"></i>
                            </a>
                          </li>
                          <li>
                            <a
                              href={item.isntagram}
                            >
                              <i className="fa fa-instagram"></i>
                            </a>
                          </li>
                        </ul>
                      </div> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHome1Team;
