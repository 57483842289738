import { Fragment, useEffect, useState } from "react";
import { loadScript, route, publicUrlFor } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import CountUp from "react-countup";
import SectionSlider1 from "../../sections/home/home1/section-slider1";
import { NavLink, useParams } from "react-router-dom";
import SectionHome1Team from "../../sections/home/home1/section-home1-team";
import SectionHome1Plans from "../../sections/home/home1/section-home1-plans";
import SectionHome1Blogs from "../../sections/home/home1/section-home1-blogs";
import SectionHome1Testimonials from "../../sections/home/home1/section-home1-testimonials";
import _data from "../../../globals/data/data.json";
import { getCounters } from "../../../api/countersApi";
import { useDispatch, useSelector } from "react-redux";
import { homeFetch } from "../../../store/homeSlice";
import { postContactUs } from "../../../api/contactusApi";
import SwiperWrapper from "../SwiperWrapper";
import { SwiperSlide } from "swiper/react";
import { Pagination, Navigation, FreeMode, Autoplay } from "swiper/modules";
import "swiper/css/navigation";
import { useTranslation } from "react-i18next";
import HomeSlider from "../../sections/home/home1/HomeSlider";
import { Audio } from "react-loader-spinner";
import Loader from "../Loader";
import ChooseusSlider from "../../sections/home/home1/ChooseusSlider";
import { pagesFetch } from "../../../store/pagesSlice";
import { JSONTree } from "react-json-tree";
import Error from "../Error";
import AlertToast from "../Alert";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function Home1Page() {
  const { lang } = useParams();
  const { t } = useTranslation();
  const dir = lang === "en" ? "ltr" : "rtl";
  const locale = lang;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [text, setText] = useState("");
  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errors, setErrors] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [values, setValues] = useState({
    email: "",
    name: "",
    message: "",
  });
  const [phone, setPhone] = useState("");

  const { email, name, message } = values;

  const { homeData, pages } = useSelector((state) => state);

  useEffect(() => {
    dispatch(homeFetch({ locale }));
    dispatch(pagesFetch({ locale }));
  }, []);

  const sliders = homeData.sliders;
  const counters = homeData.counters;
  const features = homeData.features;
  const partners = homeData.partners;
  const services = homeData.services;
  const teams = homeData.teams;
  const testimonials = homeData.testimonials;
  const chooseus = homeData.chooseus;
  const { aboutSection } = homeData;
  const { serviceSection } = homeData;
  const { teamSection } = homeData;
  const { testimonialSection } = homeData;
  const { chooseusSection } = homeData;
  const { contactSection } = pages;

  const { isLoading, loaded } = homeData;
  const errorFetch = homeData.error;

  useEffect(() => {
    loadScript("js/custom.js");
  });

  if (isLoading) {
    return <Loader />;
  }

  if (errorFetch) {
    return <h1>Fetching data error!</h1>;
  }

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    values["phone"] = phone;
    postContactUs({ locale, values })
      .then((res) => {
        setText(lang === "en" ? "Order is successful!" : "تم الطلب بنجاح!");
        setOpen(true);
        setDisabled(false);
        setError(false);
        setErrors({});
        setValues({
          name: "",
          email: "",
          message: "",
        });
        setPhone("");
      })
      .catch((err) => {
        setError(true);
        setErrors(err.response.data.data);
        setDisabled(false);
      });
  };

  const handleClose = () => {
    setOpen(false);
    setError(false);
  };

  const generateCounters = () => {
    return (
      loaded &&
      counters?.map((counter) => {
        return (
          <div key={counter.id} className="col-md-6 col-sm-6">
            <div className="m-b30 text-black mt-icon-box-wraper center bdr-1 bdr-solid bdr-gray-light  bg-white p-a20">
              <div className="counter font-42 font-weight-800 m-b15 site-text-primary">
                <CountUp end={Number(counter.count)} duration={10} />
              </div>
              <h4 className="m-tb0">{counter.title}</h4>
            </div>
          </div>
        );
      })
    );
  };

  const generateServices = () => {
    return (
      loaded &&
      services?.map((service) => {
        return (
          <SwiperSlide key={service.id}>
            <div
              style={{ height: 300, width: 300 }}
              className="item overflow-hide"
            >
              <div className="mt-box">
                <div
                  style={{ height: "100%" }}
                  className="mt-img-effect overlay-2"
                >
                  <FastImage
                    src={service?.image}
                    proImage={service?.image}
                    alt={service?.title}
                    style={{ height: 300 }}
                  />
                  <div className="overlay-2-bg bg-black" />
                  <div className="overlay-2-content">
                    <div className="p-a30 p-b20 d-flex flex-column align-items-center justify-content-center">
                      <h4 className="m-t0 m-b15 site-text-primary">
                        {service?.title}
                      </h4>
                      <div
                        className="m-b20 text-white"
                        style={{
                          fontSize: "0.7rem",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: service?.description,
                        }}
                      />

                      <NavLink
                        to={`/${lang}/service/detail/${service.id}`}
                        className="site-button"
                      >
                        {t("read_more")}
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        );
      })
    );
  };

  return (
    <div dir={dir}>
      <AlertToast open={open} message={text} handleClose={handleClose} />

      {/* Slider */}
      {loaded && <HomeSlider slides={sliders} />}
      {/* Shipment Solution*/}
      <div
        className="section-full p-t80 p-b50 bg-no-repeat bg-center bg-white"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/background/bg-map.png"
          )})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-12">
              <div className="mt-box m-b30">
                <div className="mt-thum-bx mt-img-effect  mt-img-overlay2">
                  {loaded && (
                    <FastImage
                      src={aboutSection.image[0].url}
                      proImage={aboutSection.image[0].url}
                      alt="about image"
                    />
                  )}
                  <a
                    href="https://player.vimeo.com/video/66491481"
                    className="mfp-video play-now"
                  >
                    <i className="icon fa fa-play" />
                    <span className="ripple" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-7 col-md-12">
              <div className="sx-shipment-wrap">
                <div className="mt-box m-b25">
                  <h2 className="display-block m-b15 m-t0">
                    {loaded && aboutSection.title}
                  </h2>
                  <h5 className="display-block m-b15 m-t0">
                    {loaded && aboutSection.subtitle}
                  </h5>
                  <div
                    className="m-b15"
                    dangerouslySetInnerHTML={{
                      __html: loaded && aboutSection.description,
                    }}
                  />

                  <NavLink to={`/${lang}/about-us`} className="site-button">
                    {t("read_more")}
                  </NavLink>
                </div>
                <div className="row">{generateCounters()}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Latest Project */}
      <div
        className="section-full  p-tb80 bg-full-height bg-secondry bg-repeat-x city-slide-image"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/background/bg-trans-1.png"
          )})`,
        }}
      >
        <div className="container-fluid">
          <div className="fillter-left-content">
            <div className="filter-left-part shake_image">
              <FastImage src="images/truck-2.png" alt="" />
              <span className="tyre-position">
                <FastImage
                  src="images/rotate-tyer.png"
                  alt=""
                  className="spin-tyres"
                />
              </span>
              <FastImage
                className="blink-image"
                src="images/light-blink.png"
                alt=""
              />
            </div>
            <div className="container">
              <div className="section-head text-center sx-title-mid-area">
                <div className="mt-box  text-white m-b30">
                  <h2 className="m-tb0">{loaded && serviceSection.title}</h2>
                  <p className="m-b15">{loaded && serviceSection.subtitle}</p>
                </div>
              </div>
              <div className=" filter-carousal-1-outer">
                <div className="filter-carousal-1">
                  {/* IMAGE CAROUSEL START */}
                  <div className="section-content">
                    <SwiperWrapper
                      slidesPerView={3}
                      spaceBetween={30}
                      modules={[Navigation]}
                      loop
                      className="mySwiper"
                    >
                      {generateServices()}
                    </SwiperWrapper>
                    {/* </div> */}
                  </div>
                </div>
              </div>
              <div className="text-center p-t30">
                <NavLink to={`/${lang}/services`} className="site-button">
                  {t("more_our_services")}
                </NavLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our Team */}
      {loaded && <SectionHome1Team team={teams} teamSection={teamSection} />}

      {/* Why Choose */}
      <div className="section-full bg-change-section overlay-wraper p-t80 p-b50">
        <div className="overlay-main bg-secondry opacity-09" />
        <div className="bg-changer">
          <ChooseusSlider slides={sliders} />
        </div>
        <div className="container">
          {/* TITLE START*/}
          <div className="section-head text-center text-white">
            <h2 data-title="Choose us">{loaded && chooseusSection.title} </h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          {/* TITLE END*/}
          <div className="section-content">
            <div className="row">
              {loaded &&
                chooseus.map((item) => {
                  return (
                    <div
                      key={item.id}
                      className="col-lg-4 col-md-6 col-sm-6 m-b30"
                    >
                      <div className="mt-icon-box-wraper  p-a30 bgcall-block hover-shadow hover-border-outer hover-border m-b30">
                        <div className="icon-md radius m-b15">
                          <FastImage
                            src={item.image}
                            proImage={item.image}
                            alt={item.title}
                          />
                        </div>
                        <div className="icon-content">
                          <h4 className="mt-tilte  m-b15 site-text-primary">
                            {item.title}
                          </h4>
                          <div
                            className="text-white"
                            dangerouslySetInnerHTML={{
                              __html: item.description,
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      {/* Testimonials */}
      <div dir="ltr">
        {loaded && (
          <SectionHome1Testimonials
            testimonials={testimonials}
            testimonialSection={testimonialSection}
          />
        )}
      </div>

      {/* Contact Us  */}

      {error && (
        <Error error={errors} errorsShow={error} handleClose={handleClose} />
      )}
      <div
        className="section-full bg-white bg-right-center bg-no-repeat"
        style={{
          backgroundImage: `url(${publicUrlFor(
            "images/background/bg-trans-2.png"
          )})`,
        }}
        dir={"ltr"}
      >
        <div className="container">
          <div className="row conntact-home">
            <div className="col-lg-5 col-md-5 col-sm-12">
              <div className="contact-home1-left bg-secondry">
                <div className="contact-tag-line bg-white site-text-primary">
                  {t("contact")}
                </div>
                <div className="section-content p-lr30 p-tb30">
                  <div
                    className="call-back-form"
                    style={{ zIndex: 1, position: "relative" }}
                    dir={dir}
                  >
                    <h2 className="text-white m-t0">{t("contact_us")}</h2>
                    <form
                      className="cons-contact-form2 form-transparent"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-group">
                        <input
                          name="name"
                          type="text"
                          value={name}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={t("name")}
                        />
                      </div>
                      <div className="form-group">
                        <input
                          name="email"
                          type="text"
                          value={email}
                          onChange={handleChange}
                          className="form-control"
                          placeholder={t("email")}
                        />
                      </div>
                      <div className="form-group home-phone-page">
                        <PhoneInput
                          name="phone"
                          country={lang === "ar" ? "sa" : "us"}
                          value={phone}
                          onChange={(phone) => setPhone(phone)}
                          dir="ltr"
                          inputClass="form-control"
                          inputStyle={{
                            border: 'none',
                            borderBottom: '1px solid rgb(22, 28, 42)',
                            background: 'transparent',
                            boxShadow: "none",
                            width: "100%",
                            height: 40,
                            fontSize: 13,
                            lineHeight: 20,
                            borderRadius: "6px !important",
                            direction: lang === "en" ? "ltr" : "rtl",
                            paddingInlineStart: lang === "ar" && 50,
                          }}
                          buttonStyle={{
                            background: 'transparent'
                          }}
                        />
                      </div>
                      <div className="form-group">
                        <textarea
                          name="message"
                          className="form-control"
                          rows={3}
                          value={message}
                          onChange={handleChange}
                          placeholder={t("message")}
                          defaultValue={""}
                        />
                      </div>
                      <button type="submit" className="site-button ">
                        <span className="font-weight-700 inline-block  p-lr15">
                          {t("submit")}
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-content contact-home1-right">
              <div className="mt-box">
                <div className="mt-media">
                  <FastImage
                    proImage={pages ? contactSection?.image[0].url : null}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Our Client Logo */}
      <div className="section-full bg-white p-tb50">
        <div className="container">
          <div className="section-head text-center text-white">
            <h2 style={{ color: "#000" }}>{t("partners")} </h2>
            <div className="mt-separator-outer">
              <div className="mt-separator site-bg-primary" />
            </div>
          </div>
          <div className="section-content">
            <SwiperWrapper
              slidesPerView={5}
              spaceBetween={30}
              freeMode={true}
              loop
              autoplay
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                415: {
                  slidesPerView: 1,
                  spaceBetween: 5,
                },
                640: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 4,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 5,
                  spaceBetween: 50,
                },
              }}
              modules={[FreeMode, Pagination, Autoplay]}
            >
              {loaded &&
                partners.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <FastImage
                        src={item.image}
                        proImage={item.image}
                        alt=""
                        style={{
                          width: 150,
                          height: 150,
                        }}
                      />
                    </SwiperSlide>
                  );
                })}
            </SwiperWrapper>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Home1Page;
