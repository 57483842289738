import { Fragment, useEffect, useState } from "react";
import { route, publicUrlFor } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";
import { getProducts } from "../../../../api/productsApi";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { postNewsletter } from "../../../../api/newsletterApi";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function SectionFooter1(props) {
  const settings = useSelector((state) => state.settings.value);
  const [loaded, setLoaded] = useState(false);
  const [products, setProducts] = useState([]);
  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [message, setMessage] = useState("");

  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const locale = lang;
  const dir = lang === "en" ? "ltr" : "rtl";

  useEffect(() => {
    getProducts({ locale })
      .then((res) => {
        const newArr = res.data.products.filter((pro, i) => i <= 2);
        setLoaded(true);
        setProducts(newArr);
      })
      .catch((err) => {
        console.log({ err });
      });
  }, []);

  const handleNewsletter = (e) => {
    e.preventDefault();
    postNewsletter(email)
      .then((res) => {
        setMessage(lang === "en" ? "Success" : "تم بنجاح");
        setSuccess(true);
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  const displayProducts = () => {
    return products.map((product) => {
      return (
        <div dir={dir} key={product.id} className="widget-post clearfix">
          <div className="mt-post-media text-white  overflow-hide">
            <FastImage proImage={product.image} alt="" />
          </div>
          <div className="mt-post-info">
            <div className="mt-post-header">
              <div className="post-title">
                <NavLink to={route.blog.POST_SINGLE}>{product.title}</NavLink>
              </div>
            </div>
            <div className="mt-post-meta">
              <ul>
                <li className="post-author">
                  <i className="fa fa-user site-text-primary" />
                  By Admin
                </li>
                <li className="post-comment">
                  <i className="fa fa-comments site-text-primary" /> 28
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <>
      <footer dir={dir} className={"site-footer " + props.theme}>
        {/* Footer Top Part */}
        <div className="footer-top overlay-wraper">
          <div className="overlay-main" />
          <div className="container">
            <div className="row">
              {/* About */}
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_about">
                  <h4 className="widget-title">{settings.title}</h4>
                  <div className="logo-footer clearfix p-b15">
                    <NavLink to={route.home.HOME1}>
                      <FastImage
                        proImage={settings?.white_logo}
                        width={171}
                        height={49}
                        alt=""
                      />
                    </NavLink>
                  </div>
                  <p>{settings?.description}</p>
                  <ul>
                    <li>
                      {" "}
                      <i className="fa fa-phone site-text-primary" />{" "}
                      <b className="p-lr5 text-uppercase">{t("phone")} :</b>{" "}
                      <span dir='ltr'>
                        {settings?.phones?.length
                          ? settings?.phones[0].contact
                          : null}{" "}
                      </span>
                      {" | "}{" "}
                      <span dir='ltr'>
                        {settings?.phones?.length
                          ? settings?.phones[1].contact
                          : null}
                      </span>
                    </li>
                    <li>
                      <i className="fa fa-envelope site-text-primary" />{" "}
                      <b className="p-lr5 text-uppercase">{t("email")} : </b>
                      {settings?.emails?.length
                        ? settings?.emails[0]?.contact
                        : null}
                    </li>
                    <li>
                      <i className="fa fa-location-arrow site-text-primary" />{" "}
                      <b className="p-lr5 text-uppercase">{t("location")} :</b>{" "}
                      {settings.address1}
                    </li>
                    {settings && settings.whatsapp && settings.whatsapp.length && (
                  <li>
                    <a
                      href={"https://wa.me/" + settings?.whatsapp[0]?.contact}
                      target="_blank"
                    >
                      <WhatsAppIcon /> <b className="p-lr5 text-uppercase">{settings?.whatsapp[0]?.contact}</b>
                    </a>
                  </li>
                )}
                  </ul>
                </div>
              </div>
              {/* Useful Links */}
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_services">
                  <h4 className="widget-title">{t("useful_links")}</h4>
                  <ul>
                    <li>
                      <NavLink to={`/${lang}/index`}>{t("home")}</NavLink>
                    </li>
                    <li>
                      <NavLink to={`/${lang}/about-us`}>{t("about")}</NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${lang}/services`}>
                        {t("our_services")}
                      </NavLink>
                    </li>

                    <li>
                      <NavLink to={`/${lang}/shop/products`}>
                        {t("products")}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to={`/${lang}/contact-us1`}>
                        {t("contact_us")}
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Resent Post */}
              <div className="col-lg-3 col-md-6">
                <div className="widget recent-posts-entry-date">
                  <h4 className="widget-title">{t("recent_posts")}</h4>
                  <div className="widget-post-bx">
                    {loaded ? (
                      <Fragment>{displayProducts()}</Fragment>
                    ) : (
                      <p>Loading...</p>
                    )}
                  </div>
                </div>
              </div>
              {/* Newsletter Feed */}
              <div className="col-lg-3 col-md-6">
                <div className="widget widget_newsletter f-news-letter">
                  <h4 className="widget-title">{t("news_letter")}</h4>
                  <div className="newsletter-bx m-b30">
                    <form dir="ltr" onSubmit={handleNewsletter}>
                      <div className="input-group">
                        <input
                          name="email"
                          className="form-control"
                          placeholder={t("enter_email")}
                          type="text"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                        {success && (
                          <div class="alert alert-primary" role="alert">
                            {message}
                          </div>
                        )}
                        <span className="input-group-btn">
                          <button
                            type="submit"
                            className="site-button btn font-weight-600 d-flex align-items-center justify-content-center"
                          >
                            <i className="fa fa-paper-plane" />
                          </button>
                        </span>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Copyright Part */}
        <div className="footer-bottom overlay-wraper">
          <div className="overlay-main" />
          <div className="container p-t30">
            <div className="mt-footer-bot-center">
              <span className="copyrights-text">
                {settings?.copyright}
              </span>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
export default SectionFooter1;
