import React from "react";
import SwiperWrapper from "../../../components/SwiperWrapper";
import { SwiperSlide } from "swiper/react";
import styles from "./Chooseus.module.css";
import FastImage from "../../../../globals/elements/fastimg";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { t } from "i18next";
import 'swiper/css/effect-fade';


const ChooseusSlider = ({ slides }) => {

  const displaySliders = () => {
    return slides?.map((slide) => {
      return (
        <SwiperSlide key={slide.id} className={styles.swiperSlide}>
          <div className={styles.container}>
            <div className={styles.imageContainer}>
              <FastImage
                proImage={slide.image}
                alt={slide.title}
                className={styles.image}
              />
            </div>
          </div>
        </SwiperSlide>
      );
    });
  };
  return (
    <SwiperWrapper
      slidesPerView={1}
      loop
			effect='fade'
			speed={2000}
      autoplay={true}
      modules={[Autoplay, EffectFade]}
      className={styles.swiper}
    >
      {displaySliders()}
    </SwiperWrapper>
  );
};

export default ChooseusSlider;
